import React from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";

export const removeAnalyticCookies = () => {
  // Delete all cookies from google analytics
  // Remove the default tracker.
  const windowObj: Object | any = window;
  if (windowObj.ga) windowObj.ga("remove");
  if (windowObj.gtag) windowObj.gtag = null;
  // Remove the default cookies
  // _ga is used to distinguish users.
  Cookies.remove("_ga", { path: "/", domain: document.domain });
  Cookies.remove("_ga_G3RGZ9W3RY", {
    path: "/",
    domain: document.domain,
  });
  Cookies.remove("_ga_9YJGEGM32H", {
    path: "/",
    domain: document.domain,
  });
  // _gid is used to distinguish users.
  Cookies.remove("_gid", { path: "/", domain: document.domain });
  // _gat is used to throttle request rate.
  Cookies.remove("_gat", { path: "/", domain: document.domain });
};

const CookieConsentBanner = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="I accept"
      cookieName="consent-cookie"
      style={{ background: "black" }}
      buttonStyle={{ color: "#4e503b", fontSize: "15px" }}
      expires={180}
      enableDeclineButton
      onDecline={removeAnalyticCookies}
    >
      This website uses cookies to improve user experience. By using our website
      you consent to all cookies in accordance with our{" "}
      <a href="/legal?view=cookies">Cookie Policy</a>.
    </CookieConsent>
  );
};

export default CookieConsentBanner;

const inProd = process.env.NODE_ENV === "production";

export const config = {
  api: {
    domain: inProd
      ? process.env.REACT_APP_PROD_API_DOMAIN
      : process.env.REACT_APP_DEV_API_DOMAIN || "https://localhost:2222",
  },
  cookies: {
    consentCookieLabel:
      process.env.REACT_APP_CONSENT_COOKIE_LABEL || "consent-cookie",
  },
  emails: {
    salesEmail: process.env.REACT_APP_SALES_EMAIL || "sales@adinsights.ai",
    supportEmail:
      process.env.REACT_APP_SUPPORT_EMAIL || "support@adinsights.ai",
  },
  labels: {
    brandName: process.env.REACT_APP_BRAND_NAME_LABEL || "AdInsights.ai",
  },
  links: {
    baseClientURL: inProd
      ? process.env.REACT_APP_WEB_APP_URL
      : "http://localhost:3000",
    stripeCustomerPortalURL: inProd
      ? "https://billing.stripe.com/p/login/14k00he561TqeaIfYY"
      : "https://billing.stripe.com/p/login/test_aEU7sK9ST2Ki0Xm9AA",
    salesEmail: "sales@adinsights.ai",
  },
  mixpanel: {
    token: process.env.REACT_APP_MP_TOKEN || "token",
  },
};

import "./index.css";

const PageNotFound = (_: any) => {
  return (
    <div className="page-not-found-container">
      <p>404 Page Not Found</p>
      <a href="/">Home</a>
    </div>
  );
};

export default PageNotFound;

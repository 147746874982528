import React from "react";
import queryString from "query-string";
import { config } from "../../config";
import "./index.css";

class Legal extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    const parsedQuery = queryString.parse(window.location.search);
    if (process.env.NODE_ENV !== "production") {
      console.log("LegalView parsedQuery:", parsedQuery);
    }

    this.state = {
      legalList: ["terms", "privacy", "cookies", "FAQ"],
      currentView: parsedQuery.view ? parsedQuery.view : "terms",
      brandName: config.labels.brandName,
      effectiveDate: "08/01/2024",
      supportEmail: config.emails.supportEmail,
      supportEmailLink: `mailto:${config.emails.supportEmail}?subject=Support%20Request&body=Please be very clear about your issue so our team can swiftly solve it!`,
      salesEmailLink: `mailto:${config.emails.salesEmail}?subject=Pricing`,
    };

    this.handleUpdateState = this.handleUpdateState.bind(this);
    this.renderLegalList = this.renderLegalList.bind(this);
    this.getLegalContent = this.getLegalContent.bind(this);
  }

  capitalize(label: string) {
    return label.charAt(0).toUpperCase() + label.slice(1);
  }

  handleUpdateState(key: string, value: any) {
    this.setState({
      [key]: value,
    });
  }

  renderHeader() {
    return (
      <div className="bg-white font-bold text-black px-20 py-4">
        <a href="/">{this.state.brandName}</a>
      </div>
    );
  }

  renderTerms() {
    return (
      <div className="LegalViewContent">
        <p>Terms and Conditions</p>
        <br />
        [Effective Date: {this.state.effectiveDate}] | **Terms and Conditions -{" "}
        {this.state.brandName}** These Terms and Conditions ("Terms") govern
        your use of the {this.state.brandName} web application ("the
        Application") provided by {this.state.brandName} ("we," "us," or "our").
        By accessing or using the Application, you agree to be bound by these
        Terms. If you do not agree with any part of these Terms, you may not use
        the Application. **1. Use of the Application:** 1.1 Eligibility: You
        must be at least 18 years old or have reached the age of legal majority
        in your jurisdiction to use the Application. By using the Application,
        you represent and warrant that you meet these eligibility requirements.
        1.2 Account Creation: To use the Application, you may be required to
        create an account. You agree to provide accurate and complete
        information during the registration process and to keep your account
        credentials secure and confidential. You are responsible for all
        activities that occur under your account. **2. Our Services:** Our
        expert team leverages cutting-edge AI technology, your ideal customer
        profile, and your ad campaign data to: - Analyze your historical ad
        performance and audience insights - Continuously refine targeting
        strategies for optimal results - Identify high-potential converting
        leads within your niche - Manage 100% of your LinkedIn lead generation
        efforts effortlessly Let us help you unlock the full potential of
        LinkedIn for your business growth! **3. Pricing:** $10 per high-quality
        lead. Each lead comes with: - Detailed lead profile report - AI-crafted
        personalized introduction - Verified lead information - Link to directly
        contact lead Only pay for qualified leads that match your specific
        criteria. **4. Start Growing Your Business Today:** Provide your Name,
        Email, Company, and Phone to get your first lead FOR FREE. Premium leads
        are sourced from industry giants, including Fortune 500 companies and
        fast-growing startups. **5. Testimonials:** "This service has
        revolutionized our B2B outreach. We're now connecting with
        decision-makers from top-tier companies we never had access to before."
        - Sarah Chen, VP of Sales at TechNova **6. Data Collection and Usage:**
        6.1 When completing the diagnosis form, you provide information about
        your company, products, and goals. We use AI to analyze this data and
        create a customized research plan and reports tailored to your needs.
        Your information is treated in accordance with our Privacy Policy. 6.2
        The Application may utilize AI and machine learning tools, including
        Google Analytics or similar technologies, to collect aggregated
        information about your usage patterns and improve our services. We may
        also utilize cookies to enhance your experience and provide personalized
        content. **7. User Obligations:** 7.1 Accuracy of Information: You agree
        to provide accurate and truthful information when using the
        Application's features. Misrepresentation of information may impact the
        effectiveness of the AI-generated insights and research plans provided
        to you. 7.2 Compliance: You agree to comply with all applicable laws and
        regulations when using the Application. You also agree not to use the
        Application for any unlawful, harmful, or unauthorized purposes. **8.
        Intellectual Property:** 8.1 Application Ownership: The Application,
        including all its content, features, functionality, and AI-driven tools,
        is owned by
        {this.state.brandName} and is protected by copyright, trademark, and
        other intellectual property laws. You may not reproduce, modify,
        distribute, or create derivative works based on the Application without
        our prior written consent. **9. Limitation of Liability:** 9.1
        Disclaimer of Warranties: The Application is provided on an "as is" and
        "as available" basis without any warranties, whether express, implied,
        or statutory. We do not warrant that the Application will be error-free,
        uninterrupted, or free of viruses or other harmful components. 9.2
        Limitation of Liability: To the maximum extent permitted by applicable
        law, we shall not be liable for any direct, indirect, incidental,
        consequential, or special damages arising out of or in connection with
        your use of the Application, even if we have been advised of the
        possibility of such damages. **10. Modifications and Termination:** 10.1
        Modifications: We reserve the right to modify, suspend, or discontinue
        the Application or any part thereof, at any time without prior notice or
        liability. 10.2 Termination: We may terminate or suspend your access to
        the Application, in whole or in part, if we believe that you have
        violated these Terms or engaged in any unlawful or unauthorized use of
        the Application. **11. Governing Law:** These Terms shall be governed by
        and construed in accordance with the laws of [Jurisdiction], without
        regard to its conflict of laws principles. **12. Severability:** If any
        provision of these Terms is found to be unlawful, void, or
        unenforceable, that provision shall be deemed severable from the rest of
        the Terms and shall not affect the validity and enforceability of the
        remaining provisions. **13. Entire Agreement:** These Terms constitute
        the entire agreement between you and {this.state.brandName}
        regarding the Application and supersede any prior or contemporaneous
        agreements, communications, or understandings, whether oral or written.
        By continuing to use the {this.state.brandName} Application, you
        acknowledge that you have read, understood, and agreed to these Terms
        and Conditions. If you have any questions or concerns about these Terms,
        please feel free to contact us at {config.emails.supportEmail}. **14.
        Feedback and Suggestions:** We welcome your feedback and suggestions
        about our Application. However, note that while we appreciate your
        input, we may use any feedback or suggestions you provide without any
        obligation to you. **15. Changes to Terms:** We may revise these Terms
        from time to time. The most current version will always be posted on our
        website. If a revision, in our sole discretion, is material, we will
        notify you. By continuing to access or use the Application after those
        revisions become effective, you agree to be bound by the revised Terms.
        **16. Contact Information:** For any questions about these Terms, the
        Application, or our services, please contact us at{" "}
        {config.emails.supportEmail}.
      </div>
    );
  }

  renderPrivacy() {
    return (
      <div className="LegalContent">
        <p>Privacy Policy</p>
        <br />
        [Effective Date: {this.state.effectiveDate}] | **Privacy Policy -{" "}
        {this.state.brandName}
        ** This Privacy Policy ("Policy") describes how {
          this.state.brandName
        }{" "}
        ("we," "us," or "our") collects, uses, and safeguards the personal
        information of users engaging with the {this.state.brandName} web
        application ("the Application"). Our commitment is to ensure your
        privacy and security. By accessing and using the Application, you agree
        to the practices outlined in this Policy. **1. Information We Collect:**
        1.1 Personal Information: We collect personal details such as your name,
        email address, and company information when you register, fill out
        forms, or use the Application's features. This information facilitates
        the customization of our AI-driven services, including ad analysis, lead
        generation, and sales management strategies, to suit your specific
        needs. 1.2 Usage Data: Utilizing AI tools and technologies like Google
        Analytics, we gather data on how you use our Application, including your
        IP address, browser type, visit duration, and the pages you visit, to
        enhance your user experience and improve our services. **2. Use of
        Information:** 2.1 Customized Insights: The personal and usage
        information we collect is crucial for delivering tailored AI-driven
        insights and recommendations to help optimize your marketing and sales
        strategies. 2.2 Communication: We use your email to send service
        updates, marketing messages, and other communications related to the
        Application. You have the option to unsubscribe from these
        communications at any time. **3. Data Security:** 3.1 Protection
        Measures: We implement stringent security measures, including encryption
        and firewalls, to safeguard your data against unauthorized access,
        alteration, or destruction. 3.2 Third-Party Services: While we engage
        third-party services for additional functionality, their privacy
        practices are subject to their own policies, and we encourage you to
        review them. **4. Data Retention:** 4.1 We retain your personal data
        only for as long as necessary to fulfill the purposes outlined in this
        Policy, after which it is securely deleted or anonymized. **5.
        Information Sharing:** 5.1 Your information is shared only with trusted
        partners for operational purposes or when required by law. **6.
        Children's Privacy:** 6.1 The Application is not designed for
        individuals under the age of 18. We do not intentionally collect
        personal data from children. **7. Changes to this Policy:** 7.1 This
        Policy may be updated periodically. We will notify you of any
        significant changes and encourage you to review this Policy regularly.
        **8. Contact Us:** 8.1 If you have any questions or concerns about our
        privacy practices, please contact us at {config.emails.supportEmail}. By
        engaging with the {this.state.brandName} Application, you acknowledge
        your understanding and agreement with this Privacy Policy.
      </div>
    );
  }

  renderCookies() {
    return (
      <div className="LegalContent">
        <p>Cookie Policy</p>
        <br />
        [Effective Date: {this.state.effectiveDate}] | **Cookie Policy -{" "}
        {this.state.brandName}
        ** This Cookie Policy ("Policy") outlines how {
          this.state.brandName
        }{" "}
        ("we," "us," or "our") utilizes cookies and similar tracking
        technologies on the {this.state.brandName} web application ("the
        Application"). Your use of the Application signifies your consent to our
        use of cookies in accordance with this Policy. **1. What are Cookies?**
        1.1 Cookies are small data files stored on your device when you visit
        websites. They are used to remember your preferences, enhance your
        browsing experience, and collect information about your interactions
        with the site. **2. How We Use Cookies:** 2.1 Essential Cookies: These
        are necessary for the Application to function correctly, including
        security, network management, and accessibility. Without these cookies,
        our services cannot be provided. 2.2 Performance and Analytics Cookies:
        We employ these cookies to collect information on how users interact
        with the Application, enabling us to improve functionality and user
        experience. This includes the use of AI-enhanced tools for analyzing
        user behavior and optimizing our services. 2.3 Functional Cookies: These
        cookies remember your preferences and choices (like your user name or
        language) to provide a more personalized online experience. 2.4
        Advertising Cookies: Used to deliver advertisements more relevant to you
        and your interests. They are also used to limit the number of times you
        see an advertisement as well as help measure the effectiveness of the
        advertising campaign. **3. Third-Party Cookies:** 3.1 We partner with
        third-party providers who may also set cookies on your device during
        your visit to the Application. These cookies enable third-party features
        or functionality to be provided on or through the site (e.g.,
        advertising, interactive content, and analytics). **4. Managing
        Cookies:** 4.1 You have the ability to accept or decline cookies. Most
        web browsers automatically accept cookies, but you can usually modify
        your browser setting to decline cookies if you prefer. This may prevent
        you from taking full advantage of the website. 4.2 For detailed
        information on how to manage and delete cookies, visit your browser's
        help documentation. **5. Changes to This Policy:** 5.1 We reserve the
        right to make changes to this Cookie Policy at any time. Any changes
        will be posted here and are effective immediately. By continuing to use
        the Application after changes are made, you agree to the revised Policy.
        **6. Contact Us:** 6.1 If you have any questions about our use of
        cookies or this Policy, please contact us at{" "}
        {config.emails.supportEmail}. By continuing to navigate our Application,
        you consent to our use of cookies as described in this Cookie Policy.
      </div>
    );
  }

  renderFAQ() {
    return (
      <div className="FAQContentContainer">
        <div className="FAQContent">
          <div className="FAQQuestion">
            What services does {this.state.brandName} offer?
          </div>
          <div className="FAQAnswer">
            {this.state.brandName} leverages cutting-edge AI technology to
            provide comprehensive LinkedIn lead generation services. Our
            platform helps you receive 50-100+ qualified leads in 30 days or
            your money back. We handle 100% of your LinkedIn lead generation
            efforts, from analyzing your historical ad performance to
            continuously refining targeting strategies and identifying
            high-potential leads within your niche.
          </div>
        </div>
        <div className="FAQContent">
          <div className="FAQQuestion">
            How can AI-driven lead generation benefit my business?
          </div>
          <div className="FAQAnswer">
            Our AI-driven lead generation service analyzes your ad campaign data
            and audience insights to refine targeting strategies and identify
            high-potential leads. This ensures that you connect with qualified
            leads who match your ideal customer profile, enhancing your B2B
            outreach and helping your business grow more efficiently.
          </div>
        </div>
        <div className="FAQContent">
          <div className="FAQQuestion">
            What makes your lead generation service unique?
          </div>
          <div className="FAQAnswer">
            Our service is unique because it leverages AI to continuously
            optimize your LinkedIn lead generation efforts. We provide detailed
            lead profile reports, AI-crafted personalized introductions, and
            verified lead information. You only pay for qualified leads that
            match your specific criteria, ensuring the highest quality and
            relevance for your business.
          </div>
        </div>
        <div className="FAQContent">
          <div className="FAQQuestion">What pricing plans do you offer?</div>
          <div className="FAQAnswer">
            Our pricing is straightforward: $10 per high-quality lead. Each lead
            comes with a detailed profile report, AI-crafted personalized
            introduction, verified lead information, and a direct contact link.
            You only pay for leads that meet your specific criteria, ensuring
            that you get the best value for your investment.
          </div>
        </div>
        <div className="FAQContent">
          <div className="FAQQuestion">How do I get started?</div>
          <div className="FAQAnswer">
            Getting started is easy. Simply provide your name, email, company,
            and phone number to receive your first lead for free. Our expert
            team will then leverage AI technology and your ad campaign data to
            start generating qualified leads for your business.
          </div>
        </div>
        <div className="FAQContent">
          <div className="FAQQuestion">What kind of leads can I expect?</div>
          <div className="FAQAnswer">
            Our AI-powered platform sources high-quality leads from Fortune 500
            companies and fast-growing startups. This ensures that you connect
            with decision-makers from top-tier companies, giving you access to
            valuable business opportunities that can drive your growth.
          </div>
        </div>
        <div className="FAQContent">
          <div className="FAQQuestion">Can you share any success stories?</div>
          <div className="FAQAnswer">
            Yes! Here's a testimonial from one of our satisfied clients: "This
            service has revolutionized our B2B outreach. We're now connecting
            with decision-makers from top-tier companies we never had access to
            before." - Sarah Chen, VP of Sales at TechNova
          </div>
        </div>
      </div>
    );
  }

  renderLegalList() {
    return (
      <div className="bg-black text-white px-20 py-4">
        <ul className="LegalList">
          {this.state.legalList.map((view: string, key: number) => {
            const isActive = this.state.currentView === view.toLowerCase();
            const linkStyle = isActive
              ? "LegalListItem-Active"
              : "LegalListItem-Inactive";
            view = isActive ? view.toUpperCase() : this.capitalize(view);
            return (
              <li key={key} className="LegalListItem">
                <div
                  className={`LegalListItemLink ${linkStyle}`}
                  onClick={(_: Event | any) => {
                    this.setState({ currentView: view.toLowerCase() });
                  }}
                >
                  {view}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  getLegalContent() {
    switch (this.state.currentView) {
      case "terms":
        return this.renderTerms();
      case "privacy":
        return this.renderPrivacy();
      case "cookies":
        return this.renderCookies();
      case "faq":
        return this.renderFAQ();
      default:
        return this.renderTerms();
    }
  }

  renderLegalView() {
    return (
      <div className="bg-black text-white px-20 py-4">
        <div className="LegalViewHeader">
          <p>{this.state.currentView.toUpperCase()}</p>
        </div>
        <div className="LegalViewContentContainer">
          {this.getLegalContent()}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="LegalContainer">
        {this.renderHeader()}
        {this.renderLegalList()}
        {this.renderLegalView()}
      </div>
    );
  }
}

export default Legal;

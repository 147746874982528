import "react-toastify/dist/ReactToastify.css";
import React from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { Cookies } from "react-cookie-consent";
import { ToastContainer } from "react-toastify";
import Landing from "../Landing";
import Legal from "../Legal";
import PageNotFound from "../PageNotFound";
import CookieConsentBanner, {
  removeAnalyticCookies,
} from "../../components/CookieConsentBanner";
import { config } from "../../config";

const handleRouteMiddleware = (Component: any, prevProps: any) => {
  const consentCookie = Cookies.get(config.cookies.consentCookieLabel);
  if (consentCookie && consentCookie === "false") {
    removeAnalyticCookies();
  }
  return <Component {...prevProps} />;
};

export const landingRoutePath = "/";
export const legalRoutePath = "/legal";

const Router = (props: any) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path={landingRoutePath}
          component={(_: any): any => handleRouteMiddleware(Landing, props)}
          exact
        />
        <Route
          path={`${legalRoutePath}*`}
          component={(_: any): any => handleRouteMiddleware(Legal, props)}
        />
        <Route component={PageNotFound} />
      </Switch>
      <ToastContainer />
      <CookieConsentBanner />
    </BrowserRouter>
  );
};

export default Router;
